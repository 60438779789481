import React, { useEffect, useRef, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RenderIf } from "../../../../app/components";
import { useUploadFileMutation } from "../../../../redux/api/Auth/Users/usersApi";

export default function Upload(props) {
  const { onHide, show, refetch } = props;
  const [coverFile, setCoverFile] = useState();
  const [uploadFile, { isLoading, isSuccess }] = useUploadFileMutation();

  const CoverImagePreview = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    setCoverFile(file);
    formik.setFieldValue("file", file);
    reader.readAsDataURL(file);
  };

  const initialValues = {
    file: "",
  };

  const CreateSchema = Yup.object().shape({
    file: Yup.string().required("File is required"),
  });

  const form = useRef();
  const formik = useFormik({
    initialValues,
    validationSchema: CreateSchema,
    onSubmit: (values) => {
      const formData = new FormData(form.current);
      formData.append("file", coverFile);
      uploadFile(formData);
    },
  });
  useEffect(() => {
    if (isSuccess) {
      refetch();
      onHide();
    }
  }, [isLoading]);
  return (
    <Modal show={show} onHide={onHide} aria-labelledby="delete" centered>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="delete">Upload file</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12 col-md-12 col-lg-12 mt-3">
            <input
              accept=".xlsx, .xls"
              id="contained-button-file"
              type="file"
              name="file"
              label="File"
              placeholder="file"
              className="w-100"
              onChange={CoverImagePreview}
            />
            <RenderIf condition={formik.touched.file && formik.errors.file}>
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.file}</div>
              </div>
            </RenderIf>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
            }}
          >
            Close
          </Button>
          <Button type="submit" variant="danger">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
