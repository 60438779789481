import { createApi } from "@reduxjs/toolkit/query/react";
import { setInterests } from "../../features/Interests/interestsSlice";
import { APIBaseQuery } from "../AxiosBase";

export const interestsApi = createApi({
  reducerPath: "interestsApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getInterests: builder.query({
      query(filter) {
        const { skip, take, field, orderBy, name } = filter;
        return {
          url: `interest/filter?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${orderBy}${
            name ? `&Name=${name.name}` : ""
          }`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(setInterests(data.data));
        } catch (error) {}
      },
    }),
    getInterestsWithoutFilter: builder.query({
      query(filter) {
        return {
          url: `interest/filter`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(setInterests(data.data));
        } catch (error) {}
      },
    }),
    getInterest: builder.query({
      query(id) {
        return {
          url: `interest/byId?id=${id}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    createInterest: builder.mutation({
      query(form) {
        return {
          url: "interest",
          method: "POST",
          data: form,
        };
      },
      transformResponse: (result) => result,
    }),
    updateInterest: builder.mutation({
      query(data) {
        return {
          url: "interest",
          method: "PUT",
          data: data,
        };
      },
      transformResponse: (result) => result,
    }),
    deleteInterest: builder.mutation({
      query(id) {
        return {
          url: `interest`,
          method: "DELETE",
          data: { id: id },
        };
      },
    }),
  }),
});

export const {
  useCreateInterestMutation,
  useDeleteInterestMutation,
  useGetInterestQuery,
  useGetInterestsQuery,
  useGetInterestsWithoutFilterQuery,
  useUpdateInterestMutation,
} = interestsApi;
