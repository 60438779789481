import React from "react";
import { CiHashtag } from "react-icons/ci";
import { makeStyles } from "@material-ui/core/styles/index";
import Drawer from "@material-ui/core/Drawer/index";
import Divider from "@material-ui/core/Divider/index";
import CloseIcon from "@material-ui/icons/Close";
import Card from "@material-ui/core/Card/index";
import CardHeader from "@material-ui/core/CardHeader/index";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import "../../../../_metronic/_assets/plugins/flaticon2/flaticon.css";
import { useGetNewsQuery } from "../../../../redux/api/News/newsApi";
import { RenderIf } from "../../../components";
import { Carousel } from "react-responsive-carousel";
const { REACT_APP_BASE_PHOTO_URL_MAIN } = process.env;

const useStyles = makeStyles({
  list: {
    width: 400,
    height: "100vh",
  },
  card: {
    margin: "30px",
  },
  pointer: {
    cursor: "pointer",
  },
});
const imgTypes = ["tif", "tiff", "bmp", "jpg", "jpeg", "gif", "png", "raw"];
const videoTypes = [
  "3gp",
  "avi",
  "flv",
  "mkv",
  "mov",
  "mp4",
  "mpeg",
  "mpg",
  "ogg",
  "rm",
  "vob",
  "webm",
  "wmv",
];
export default function View(props) {
  const { onClose, id } = props;

  const { data } = useGetNewsQuery(id);
  const classes = useStyles();
  const sideList = (side) => (
    <div className={classes.list} role="presentation">
      <div className="col-12 d-flex align-items-center justify-content-between py-4">
        <h1 className="h3 mb-0">News</h1>
        <CloseIcon className={classes.pointer} onClick={onClose} />
      </div>
      <Divider className="bg-dark" />
      <div className="drawer-body">
        <Card className={classes.card}>
          <CardHeader
            title="General information"
            className="bg-secondary text-dark h6 p-3"
            disableTypography
          />

          <CardHeader
            avatar={
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Chat6.svg")}
                className="icon-primary"
              />
            }
            title={data?.title}
            subheader="Name"
            className="p-3"
          />
          <CardHeader
            avatar={
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Chat6.svg")}
                className="icon-primary"
              />
            }
            title={data?.subTitle}
            subheader="SubTitle"
            className="p-3"
          />
          <CardHeader
            avatar={
              <div className="my-2">
                <img
                  style={{
                    width: "40px",
                    height: "40px",
                    objectFit: "cover",
                    borderRadius: "100%",
                  }}
                  src={`${REACT_APP_BASE_PHOTO_URL_MAIN}${data?.fileUrl}`}
                  alt="contributor"
                />
              </div>
            }
            subheader="Photo"
            className="p-3"
          />
        </Card>

        <Card className={classes.card}>
          <CardHeader
            title="Additional information"
            className="bg-secondary text-dark h6 p-3"
            disableTypography
          />

          <CardHeader
            avatar={<i class="flaticon2-sheet icon-flaticon"></i>}
            title={
              <p
                dangerouslySetInnerHTML={{
                  __html: data?.description,
                }}
              ></p>
            }
            subheader="Description"
            className="p-3"
          />

          <CardHeader
            avatar={<CiHashtag />}
            title={data?.newsInterests?.map((c) => (
              <span
                key={c?.id}
                style={{ marginRight: "5px" }}
              >{`#${c?.interestName}`}</span>
            ))}
            subheader="Tags"
            className="p-3"
          />
          <RenderIf condition={data?.newsFiles?.length}>
            <CardHeader
              title={
                <div
                  style={{
                    width: "300px",
                    height: "200px",
                  }}
                  className="fileContent fileSlider"
                >
                  <Carousel
                    infiniteLoop
                    showArrows={true}
                    showThumbs={true}
                    className="editSlider"
                  >
                    {data?.newsFiles?.map((el, index) => {
                      const extension = el.fileUrl
                        .split(".")
                        .pop()
                        .toLowerCase();
                      if (imgTypes.includes(extension)) {
                        return (
                          <img
                            style={{
                              width: "100%",
                              height: "200px",
                            }}
                            key={el?.id}
                            src={`${REACT_APP_BASE_PHOTO_URL_MAIN}${el.fileUrl}`}
                            alt="img"
                          />
                        );
                      } else if (videoTypes.includes(extension)) {
                        return (
                          <video
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                            key={el?.id}
                            controls
                            src={`${REACT_APP_BASE_PHOTO_URL_MAIN}${el.fileUrl}`}
                          ></video>
                        );
                      }
                    })}
                  </Carousel>
                </div>
              }
              subheader="Slider files"
              className="p-3"
            />
          </RenderIf>
        </Card>
      </div>
    </div>
  );

  return (
    <Drawer {...props} anchor="right" className="drawer--fixed">
      {sideList("right")}
    </Drawer>
  );
}
