import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../../AxiosBase";

export const usersApi = createApi({
  reducerPath: "usersApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getUsers: builder.query({
      query(data) {
        const { skip, take, field, order, name } = data;

        // URL parametrelerini bir dizi olarak hazırlayın
        const queryParams = [];

        // Skip parametresi var ise (0 da dahil) URL'ye ekleyin
        if (skip !== undefined) {
          queryParams.push(`Skip=${skip}`);
        }
        if (take) {
          queryParams.push(`Take=${take}`);
        }
        if (field) {
          queryParams.push(`SortField=${field}`);
        }
        if (order) {
          queryParams.push(`OrderBy=${order}`);
        }
        if (name?.firstName) {
          queryParams.push(`FirstName=${name?.firstName}`);
        }
        if (name?.lastName) {
          queryParams.push(`LastName=${name?.lastName}`);
        }
        if (name?.email) {
          queryParams.push(`Email=${name?.email}`);
        }

        return {
          url: `siteUser/filter?${queryParams.join("&")}`,
        };
      },

      transformResponse: (result) => result,
    }),
    getChooseUser: builder.query({
      query(id) {
        return {
          url: `siteUser/byId?id=${id}`,
        };
      },
      transformResponse: (result) => result,
    }),
    updateUserPassword: builder.mutation({
      query(data) {
        return {
          url: "siteUser/changePassword",
          method: "PUT",
          data: data,
        };
      },
      transformResponse: (result) => result,
    }),
    updateUsers: builder.mutation({
      query(data) {
        return {
          url: "siteUser/update",
          method: "PUT",
          data,
        };
      },
      transformResponse: (result) => result,
    }),
    createUsers: builder.mutation({
      query(form) {
        return {
          url: "backoffices/register",
          method: "POST",
          data: form,
        };
      },
      transformResponse: (result) => result,
    }),
    updateUserStatus: builder.mutation({
      query(data) {
        return {
          url: "siteUser/updateStatus",
          method: "PUT",
          data: data,
        };
      },
      transformResponse: (result) => result,
    }),
    deleteSiteUser: builder.mutation({
      query(id) {
        return {
          url: `siteUser`,
          method: "DELETE",
          data: { id: id },
        };
      },
    }),

    getDepartments: builder.query({
      query(filter) {
        const { skip, take, field, orderBy, name } = filter;
        return {
          url: `departments/filter?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${orderBy}${
            name ? `&Name=${name.name}` : ""
          }`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    getDepartment: builder.query({
      query(id) {
        return {
          url: `departments/byId?id=${id}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    createDepartment: builder.mutation({
      query(form) {
        return {
          url: "departments",
          method: "POST",
          data: form,
        };
      },
      transformResponse: (result) => result,
    }),
    updateDepartment: builder.mutation({
      query(form) {
        return {
          url: "departments",
          method: "PUT",
          data: form,
        };
      },
      transformResponse: (result) => result,
    }),
    deleteDepartment: builder.mutation({
      query(id) {
        return {
          url: `departments`,
          method: "DELETE",
          data: { id: id },
        };
      },
    }),

    getPositions: builder.query({
      query(filter) {
        const { skip, take, field, orderBy, name } = filter;
        return {
          url: `position/filter?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${orderBy}${
            name ? `&Name=${name.name}` : ""
          }`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    getPosition: builder.query({
      query(id) {
        return {
          url: `position/byId?id=${id}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    createPosition: builder.mutation({
      query(form) {
        return {
          url: "position",
          method: "POST",
          data: form,
        };
      },
      transformResponse: (result) => result,
    }),
    updatePosition: builder.mutation({
      query(form) {
        return {
          url: "position",
          method: "PUT",
          data: form,
        };
      },
      transformResponse: (result) => result,
    }),
    deletePosition: builder.mutation({
      query(id) {
        return {
          url: `position`,
          method: "DELETE",
          data: { id: id },
        };
      },
    }),

    getCompanies: builder.query({
      query(filter) {
        const { skip, take, field, orderBy, name } = filter;
        return {
          url: `company/filter?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${orderBy}${
            name ? `&Name=${name.name}` : ""
          }`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    getCompany: builder.query({
      query(id) {
        return {
          url: `company/byId?id=${id}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    createCompany: builder.mutation({
      query(form) {
        return {
          url: "company",
          method: "POST",
          data: form,
        };
      },
      transformResponse: (result) => result,
    }),
    updateCompany: builder.mutation({
      query(form) {
        return {
          url: "company",
          method: "PUT",
          data: form,
        };
      },
      transformResponse: (result) => result,
    }),
    deleteCompany: builder.mutation({
      query(id) {
        return {
          url: `company`,
          method: "DELETE",
          data: { id: id },
        };
      },
    }),
    getExportUsers: builder.query({
      query(filter) {
        const { skip, take, orderBy, name } = filter;

        return {
          url: `siteUser/excelExport?${skip ? `&Skip=${skip}` : ""}${
            take ? `&Take=${take}` : ""
          }${orderBy ? `&OrderBy=${orderBy}` : ""}${
            name?.firstName ? `&FirstName=${name?.firstName}` : ""
          }${name?.lastName ? `&LastName=${name?.lastName}` : ""}${
            name?.email ? `&Email=${name?.email}` : ""
          }`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    uploadFile: builder.mutation({
      query(form) {
        return {
          url: "siteUser/activeUsers",
          method: "POST",
          data: form,
        };
      },
      transformResponse: (result) => result,
    }),
  }),
});

export const {
  useUploadFileMutation,
  useLazyGetExportUsersQuery,
  useGetCompaniesQuery,
  useGetCompanyQuery,
  useCreateCompanyMutation,
  useUpdateCompanyMutation,
  useDeleteCompanyMutation,
  useGetDepartmentQuery,
  useGetDepartmentsQuery,
  useCreateDepartmentMutation,
  useUpdateDepartmentMutation,
  useDeleteDepartmentMutation,
  useGetPositionQuery,
  useGetPositionsQuery,
  useUpdatePositionMutation,
  useDeletePositionMutation,
  useCreatePositionMutation,
  useDeleteSiteUserMutation,
  useUpdateUserStatusMutation,
  useCreateUsersMutation,
  useGetUsersQuery,
  useUpdateUsersMutation,
  useUpdateUserPasswordMutation,
  useGetChooseUserQuery,
} = usersApi;
