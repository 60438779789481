import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import CreatableSelect from "react-select/creatable";
import { FileUploader } from "react-drag-drop-files";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import TextField from "@material-ui/core/TextField";
import { Formik } from "formik";
import * as Yup from "yup";
import { RenderIf, ReactSelect, RichTextEditor } from "../../../components";
import {
  useGetNewsQuery,
  useUpdateNewsMutation,
} from "../../../../redux/api/News/newsApi";
import { useGetInterestsWithoutFilterQuery } from "../../../../redux/api/Interests/interestsApi";
import { videoTypes } from "../../../constants";

const { REACT_APP_BASE_PHOTO_URL_MAIN } = process.env;
export default function Edit(props) {
  const { onHide, show, refetch, id } = props;
  const { data: datas, isSuccess: singleTag } = useGetNewsQuery(id);
  const newsTags = datas?.newsInterests;
  const {
    data: tagList,
    isSuccess: isTagListSuccess,
  } = useGetInterestsWithoutFilterQuery();
  const tagLists = tagList?.data;
  const [content, setContent] = useState(false);
  const [fileInitial, setFileInitial] = useState();

  const [list, setList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [defaultVal, setDefaultVal] = useState([]);
  const [tagsApi, SetTagsApi] = useState([]);
  const [newsTagsApi, SetNewsTagsApi] = useState([]);
  const [deletedTags, setDeletedTags] = useState([]);

  const handleOnChange = (value) => {
    const difference = selected?.filter((x) => !value.includes(x));
    difference?.forEach((opt) => {
      if (defaultVal?.includes(opt)) {
        setDeletedTags([...deletedTags, opt]);
      } else if (tagsApi?.includes(opt)) {
        const TagsApiClone = tagsApi?.filter((optApi) => optApi !== opt);
        SetTagsApi(TagsApiClone);
      } else {
        const newsTagsApiClone = newsTagsApi?.filter(
          (optApi) => optApi !== opt
        );
        SetNewsTagsApi(newsTagsApiClone);
      }
    });
    value?.forEach((val) => {
      if (!defaultVal?.includes(val)) {
        if (val?.__isNew__) {
          !newsTagsApi?.includes(val) && SetNewsTagsApi([...newsTagsApi, val]);
        } else {
          !tagsApi?.includes(val) &&
            !defaultVal?.includes(val) &&
            SetTagsApi([...tagsApi, val]);
        }
      }
    });
    setSelected(value);
  };

  const onTypes = () => {
    const assets = tagLists?.map((suggestion) => ({
      value: suggestion?.id,
      label: suggestion?.name,
    }));
    setList(assets);
    const selectedDefault = newsTags?.map((item) => {
      return {
        value: item?.id,
        label: item?.interestName,
      };
    });
    setSelected(selectedDefault);
    setDefaultVal(selectedDefault);
  };

  useEffect(() => {
    if (isTagListSuccess && singleTag) onTypes();
  }, [isTagListSuccess, singleTag]);
  const options = [
    { value: 1, label: "News" },
    { value: 2, label: "Announcements" },
  ];
  const [newsType, setNewsType] = React.useState(null);

  function handleChangeType(value, setFieldValue) {
    setNewsType((prevState) => value);
    setFieldValue("typeId", value?.value);
  }

  useEffect(() => {
    setNewsType((prev) =>
      options?.find((option) => option.value === datas?.typeId)
    );
  }, [datas?.typeId]);

  const filePath = REACT_APP_BASE_PHOTO_URL_MAIN + datas?.fileUrl;
  const [file, setFile] = useState("");
  const [preview, setPreview] = useState(true);

  const [imageSrc, setImageSrc] = useState(filePath);
  const filterVideos = datas?.newsFiles?.filter((c) => c.newsType === 2);
  const filterImages = datas?.newsFiles?.filter((c) => c.newsType === 1);
  const [videos, setVideos] = useState();
  const [videoUrls, setVideoUrls] = useState([]);
  const [sliderFile, setSliderFile] = useState([]);
  const [sliderImageSrc, setSliderImageSrc] = useState([]);
  const [deletedFilesId, setDeletedFilesId] = React.useState(null);

  const onUpdateVideos = () => {
    const assets = filterVideos?.map((suggestion) => ({
      id: suggestion.id,
      fileUrl: suggestion.fileUrl,
    }));
    setVideos(assets);
    setVideoUrls(assets);
  };
  const onUpdateImages = () => {
    const assets = filterImages?.map((suggestion) => ({
      id: suggestion.id,
      fileUrl: suggestion.fileUrl,
    }));
    setSliderImageSrc(assets);
  };

  const sliderImagePreview = (e) => {
    let files = e.target.files;
    let fileArray = [];
    let imageSrcArray = [];

    for (let i = 0; i < files.length; i++) {
      let file = files[i];

      fileArray.push(file);
      let reader = new FileReader();

      reader.onloadend = () => {
        imageSrcArray.push({
          src: reader.result,
          id: uuid(),
        });

        if (i === files.length - 1) {
          setSliderFile((prevFiles) => [...prevFiles, ...fileArray]);
          setSliderImageSrc((prevImageSrc) => [
            ...prevImageSrc,
            ...imageSrcArray,
          ]);
        }
      };

      reader.readAsDataURL(file);
    }
  };
  const handleChangeVideo = (el) => {
    const videoList = [];
    const newVideoUrls = [];

    for (let i = 0; i < el.length; i++) {
      const file = el[i];
      newVideoUrls.push(file);

      const video = {
        ...file,
        name: file.name,
        type: file.type,
        url: URL.createObjectURL(file),
      };
      videoList.push(video);
    }

    setVideos((prevVideos) => [...prevVideos, ...newVideoUrls]);
    setVideoUrls((prevUrls) => [...prevUrls, ...videoList]);
  };

  const handleRemoveFileItem = (fileIndex) => {
    const clickedItem = videos?.find((el, index) => index === fileIndex);

    const updatedVideos = videos?.filter((file, index) => index !== fileIndex);
    if (clickedItem) {
      setDeletedFilesId([...(deletedFilesId || []), clickedItem.id]);
    }

    setVideos(updatedVideos);
    setVideoUrls(updatedVideos);
  };
  const handleRemoveImage = (fileIndex, values, setValues) => {
    const clickedItem = sliderImageSrc?.find(
      (el, index) => index === fileIndex
    );

    const updatedVideos = sliderImageSrc?.filter(
      (file, index) => index !== fileIndex
    );

    if (clickedItem) {
      setDeletedFilesId([...(deletedFilesId || []), clickedItem.id]);
    }

    setSliderImageSrc(updatedVideos);
  };

  useEffect(() => {
    setImageSrc(filePath);
  }, [filePath]);

  const imagePreview = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    setFile(file);

    setFileInitial(e.target.value);
    reader.onloadend = () => {
      setImageSrc(reader.result);
    };

    reader.readAsDataURL(file);
    setPreview(true);
  };
  const onClick = (e) => {
    setImageSrc("");
    setPreview(false);
  };

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const [
    updateContributor,
    { isLoading, error, isSuccess },
  ] = useUpdateNewsMutation();

  useEffect(() => {
    if (isSuccess) {
      refetch();
      onHide();
    }
  }, [isLoading]);
  useEffect(() => {
    if (singleTag) onUpdateVideos();
    onUpdateImages();
  }, [singleTag]);
  const form = useRef(null);

  const EditSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    description: Yup.string()
      .required("Description is required")
      .test("rich-text-not-empty", "Description cannot be empty", (value) => {
        if (typeof value !== "string") return false;
        const trimmedValue = value.trim();
        return trimmedValue !== "<p><br></p>" && trimmedValue !== "";
      }),
    subTitle: Yup.string().required("Subtitle is required"),
    file: Yup.string().required("File is required"),
  });

  const getInputClasses = (touched, errors, FieldName) => {
    if (touched[FieldName] && errors[FieldName]) {
      return true;
    }
    if (touched[FieldName] && errors[FieldName]) {
      return false;
    }

    return false;
  };

  const onPutNews = (values, resetForm, setSubmitting) => {
    const news = new FormData();
    news.append("Id", id);
    news.append("typeId", String(values.typeId));
    if (tagsApi?.length) {
      for (let i = 0; i < tagsApi?.length; i++) {
        news.append("Interests", tagsApi?.[i].value);
      }
    }

    if (newsTagsApi?.length) {
      for (let i = 0; i < newsTagsApi?.length; i++) {
        news.append("NewInterests", newsTagsApi?.[i].label);
      }
    }
    if (deletedTags?.length) {
      for (let i = 0; i < deletedTags?.length; i++) {
        news.append("DeletedInterests", deletedTags?.[i].value);
      }
    }
    if (videos?.length) {
      for (let i = 0; i < videos?.length; i++) {
        news.append(`videoFiles`, videos?.[i]);
      }
    }
    if (sliderFile?.length) {
      for (let i = 0; i < sliderFile?.length; i++) {
        news.append(`imageFiles`, sliderFile?.[i]);
      }
    }
    if (deletedFilesId?.length) {
      for (let i = 0; i < deletedFilesId?.length; i++) {
        news.append("deletedFiles", deletedFilesId?.[i]);
      }
    }
    news.append("file", file);
    news.append("title", values.title);
    news.append("subTitle", values.subTitle);
    news.append("description", content);

    updateContributor(news);
  };

  useEffect(() => {
    if (datas) {
      setContent(datas?.description);
    }
  }, [datas]);
  return (
    <Modal
      size="xl"
      onHide={onHide}
      show={show}
      aria-labelledby="edit"
      className="modal-center pr-0"
      centered
    >
      <RenderIf
        condition={
          datas &&
          Object.keys(datas).length !== 0 &&
          datas.constructor === Object
        }
      >
        <Formik
          initialValues={{
            title: datas?.title,
            newTags: datas?.newTags,
            description: datas?.description,
            subTitle: datas?.subTitle,
            file: datas?.fileUrl,
            typeId: datas?.typeId,
            newsFiles: datas?.newsFiles,
          }}
          validationSchema={EditSchema}
          onSubmit={(values, errors, resetForm, setSubmitting) => {
            onPutNews(values, resetForm, setSubmitting);
          }}
        >
          {({
            values,
            errors,
            touched,
            setValues,
            setFieldValue,
            handleSubmit,
            handleChange,
            handleBlur,
            resetForm,
          }) => (
            <>
              <form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                  <Modal.Title id="edit">Edit</Modal.Title>
                </Modal.Header>
                <Modal.Body id="userModal">
                  <div className="col-12 pl-4 pr-4">
                    <div className="row">
                      <div className="col-12 col-md-12 col-lg-6">
                        <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                          <TextField
                            id="title"
                            name="title"
                            label="Name"
                            placeholder="Name"
                            className="w-100"
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={getInputClasses(touched, errors, "title")}
                            onKeyDown={handleEnter}
                          />
                        </div>
                        <RenderIf
                          condition={touched.title && errors.title}
                          renderElse={null}
                        >
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{errors.title}</div>
                          </div>
                        </RenderIf>
                      </div>
                      <div className="col-12 col-md-12 col-lg-6">
                        <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                          <TextField
                            id="subTitle"
                            name="subTitle"
                            label="SubTitle"
                            placeholder="SubTitle"
                            className="w-100"
                            value={values.subTitle}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={getInputClasses(touched, errors, "subTitle")}
                            onKeyDown={handleEnter}
                          />
                        </div>
                        <RenderIf
                          condition={touched.subTitle && errors.subTitle}
                          renderElse={null}
                        >
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {errors.subTitle}
                            </div>
                          </div>
                        </RenderIf>
                      </div>

                      <div className="col-12 col-md-12 col-lg-6">
                        <ReactSelect
                          options={options}
                          onChange={(value) =>
                            handleChangeType(value, setFieldValue)
                          }
                          single={newsType}
                          placeholder="Type"
                          instanceId="typeId"
                          onKeyDown={handleEnter}
                        />
                      </div>
                      <div className="col-12 col-md-12 col-lg-6 mt-4">
                        <CreatableSelect
                          isMulti
                          defaultValue={newsTags?.map((item) => {
                            return {
                              value: item?.interestId,
                              label: item?.interestName,
                            };
                          })}
                          onChange={handleOnChange}
                          value={selected}
                          options={list}
                          placeholder="Tags"
                          id="creatable-select"
                          instanceId="creatable-select"
                          className="creatable-select"
                          classNamePrefix="creatable-react-select"
                        />
                      </div>
                      <div className="col-12 col-md-12 col-lg-6 mt-4">
                        <span>News slider</span>

                        <input
                          accept="image/*"
                          id="newsFiles"
                          type="file"
                          name="newsFiles"
                          label="File"
                          placeholder="File Slider"
                          className="w-100"
                          multiple
                          onChange={(e) => {
                            sliderImagePreview(e);
                          }}
                        />

                        <RenderIf condition={sliderImageSrc?.length}>
                          <div className="fileContent fileSlider mt-3">
                            <Carousel
                              infiniteLoop
                              showArrows={true}
                              showThumbs={true}
                              className="editSlider"
                              selectedItem={sliderImageSrc?.length - 1}
                            >
                              {sliderImageSrc?.map((el, index) => {
                                return (
                                  <div
                                    className="otherPhotoImgSide"
                                    key={index}
                                  >
                                    {el.fileUrl ? (
                                      <img
                                        style={{
                                          objectFit: "cover",
                                        }}
                                        src={`${REACT_APP_BASE_PHOTO_URL_MAIN}${el.fileUrl}`}
                                        alt="img"
                                      />
                                    ) : (
                                      <img
                                        style={{
                                          objectFit: "cover",
                                        }}
                                        src={el.src}
                                        alt="img"
                                      />
                                    )}
                                    <div
                                      className="deleteIcon"
                                      onClick={() =>
                                        handleRemoveImage(
                                          index,
                                          values,
                                          setValues
                                        )
                                      }
                                    >
                                      <i className="flaticon2-cancel-music"></i>
                                    </div>
                                  </div>
                                );
                              })}
                            </Carousel>
                          </div>
                        </RenderIf>
                      </div>

                      <div className="col-12 col-md-12 col-lg-6 selectImg">
                        <FileUploader
                          types={videoTypes}
                          accept="video/*"
                          multiple
                          handleChange={handleChangeVideo}
                          onChange={handleChangeVideo}
                        />
                        <RenderIf condition={videos?.length}>
                          <div className="fileContent fileSlider">
                            <Carousel
                              infiniteLoop
                              showArrows={true}
                              showThumbs={true}
                              className="editSlider"
                              selectedItem={videos?.length - 1}
                            >
                              {videoUrls?.map((el, index) => {
                                const videoUrl =
                                  el?.fileUrl &&
                                  el?.fileUrl.startsWith("/uploads/library/")
                                    ? `${REACT_APP_BASE_PHOTO_URL_MAIN}${el?.fileUrl}`
                                    : el?.url;
                                return (
                                  <div
                                    className="otherPhotoImgSide"
                                    key={index}
                                  >
                                    <video src={videoUrl} controls></video>

                                    <div
                                      className="deleteIcon"
                                      onClick={() =>
                                        handleRemoveFileItem(index, el)
                                      }
                                    >
                                      <i className="flaticon2-cancel-music"></i>
                                    </div>
                                  </div>
                                );
                              })}
                            </Carousel>
                          </div>
                        </RenderIf>
                      </div>

                      <div className="col-12 col-md-12 col-lg-6">
                        <div className="w-100 pt-3 pb-3">
                          <label htmlFor="contained-button-file">
                            <span>Cover photo</span>

                            <input
                              accept="image/*"
                              id="contained-button-file"
                              type="file"
                              name="file"
                              label="Cover file"
                              placeholder="File Path"
                              className="w-100"
                              onChange={(e) => {
                                imagePreview(e);
                                setValues({
                                  ...values,
                                  file: e.target.files[0],
                                });
                              }}
                              value={fileInitial}
                            />
                          </label>
                          <RenderIf condition={touched.file && errors.file}>
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">{errors.file}</div>
                            </div>
                          </RenderIf>
                          <RenderIf condition={imageSrc}>
                            <div className="image-preview mt-4">
                              <div
                                style={{
                                  position: "relative",
                                  width: "100px",
                                  height: "100px",
                                }}
                              >
                                <img
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    objectFit: "cover",
                                    borderRadius: "100%",
                                  }}
                                  src={imageSrc}
                                  alt="img"
                                />
                                <button
                                  style={{
                                    position: "absolute",
                                    right: "-30px",
                                  }}
                                  className="BtnSvg"
                                  onClick={() => {
                                    onClick();
                                    setFieldValue("file", "");
                                  }}
                                >
                                  <i
                                    style={{
                                      fontSize: "10px",
                                    }}
                                    className="flaticon2-delete"
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </RenderIf>
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-lg-12">
                        <div className="w-100 pt-2 pb-3">
                          <RichTextEditor
                            onChange={(newContent) => {
                              const trimmedContent = newContent.trim();
                              const isEmpty = trimmedContent === "<p><br></p>";
                              setContent(isEmpty ? "" : newContent);
                              setFieldValue(
                                "description",
                                isEmpty ? "" : newContent
                              );
                            }}
                            value={content}
                          />
                        </div>
                        <RenderIf
                          condition={touched.description && errors.description}
                        >
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {errors.description}
                            </div>
                          </div>
                        </RenderIf>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="light"
                    onClick={() => {
                      onHide(false);
                      resetForm();
                    }}
                  >
                    Close
                  </Button>
                  <Button type="submit" disabled={isLoading} className={`px-9`}>
                    Save
                    <RenderIf condition={isLoading}>
                      <span className="ml-3 spinner spinner-white"></span>
                    </RenderIf>
                  </Button>
                </Modal.Footer>
              </form>
            </>
          )}
        </Formik>
      </RenderIf>
    </Modal>
  );
}
