import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Checkbox from "@mui/material/Checkbox";
import CreatableSelect from "react-select/creatable";
import TextField from "@material-ui/core/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RenderIf, ReactSelect, RichTextEditor } from "../../../components";
import {
  useCreateEventMutation,
  useGetAllEventTypeWithoutFilterQuery,
} from "../../../../redux/api/Events/eventsApi";
import { formatDate } from "../../../../Helpers/formatDate";
import { dateConvertItemOther } from "../../../../Helpers/dateConvertOther";
import DatePickers from "../../../components/ReactDatePicker/DatePicker";
import { useGetInterestsWithoutFilterQuery } from "../../../../redux/api/Interests/interestsApi";
const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function Create(props) {
  const { show, onHide, refetch } = props;
  const [file, setFile] = useState("");
  const [imageSrc, setImageSrc] = useState("");
  const [fromClock, setFromClock] = useState(null);
  const [toClock, setToClock] = useState(null);
  const [date, setDate] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const {
    data: tagList,
    isSuccess: isTagListSuccess,
  } = useGetInterestsWithoutFilterQuery();
  const tagLists = tagList?.data;
  const [list, setList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [tagsApi, SetTagsApi] = useState([]);
  const [newsTagsApi, SetNewsTagsApi] = useState([]);
  const [defaultVal, setDefaultVal] = useState([]);
  const handleOnChange = (value) => {
    const difference = selected?.filter((x) => !value.includes(x));
    difference?.forEach((opt) => {
      if (tagsApi?.includes(opt)) {
        const TagsApiClone = tagsApi?.filter((optApi) => optApi !== opt);
        SetTagsApi(TagsApiClone);
      } else {
        const newsTagsApiClone = newsTagsApi?.filter(
          (optApi) => optApi !== opt
        );
        SetNewsTagsApi(newsTagsApiClone);
      }
    });
    value?.forEach((val) => {
      if (!defaultVal?.includes(val)) {
        if (val?.__isNew__) {
          !newsTagsApi?.includes(val) && SetNewsTagsApi([...newsTagsApi, val]);
        } else {
          !tagsApi?.includes(val) &&
            !defaultVal?.includes(val) &&
            SetTagsApi([...tagsApi, val]);
        }
      }
    });
    setSelected(value);
  };

  const handleChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const { data: eventType } = useGetAllEventTypeWithoutFilterQuery();
  const eventTypes = eventType?.data;
  const [eventTypeOption, setEventTypeOption] = React.useState(null);
  const eventTypeOptions = eventTypes?.map((type) => ({
    value: type.id,
    label: type.title,
  }));
  function handleChangeReason(value) {
    setEventTypeOption(value);
    formik.setFieldValue("eventTypeId", value.value);
  }

  const options = [
    { value: 1, label: "Online" },
    { value: 2, label: "Offline" },
  ];
  const [selectedValue, setSelectedValue] = useState(false);
  const selectedValues = options?.map((type) => ({
    value: type.value,
    label: type.label,
  }));
  function handleChangeValue(value) {
    setSelectedValue(value);
    formik.setFieldValue("eventFormat", value.value);
  }
  const initialValues = {
    title: "",
    file: "",
    description: "",
    eventTypeId: "",
    eventFormat: "",
    promoted: false,
    fromClock: "",
    toClock: "",
    date: "",
    link: "",
  };
  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };
  const imagePreview = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    setFile(file);
    formik.setFieldValue("file", file);

    reader.onloadend = () => {
      setImageSrc(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const onClick = (e) => {
    setImageSrc("");
    setFile("");
  };
  const [
    createEvent,
    { isLoading, error, isSuccess },
  ] = useCreateEventMutation();

  const CreateSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    description: Yup.string()
      .required("Description is required")
      .test("rich-text-not-empty", "Description cannot be empty", (value) => {
        if (typeof value !== "string") return false;
        const trimmedValue = value.trim();
        return trimmedValue !== "<p><br></p>" && trimmedValue !== "";
      }),
    eventFormat: Yup.string().required("Event format is required"),
    link: Yup.string().required("Link format is required"),
    eventTypeId: Yup.string().required("Event Type is required"),
    file: Yup.string().required("File is required"),

    fromClock: Yup.string().required("Clock is required"),
    toClock: Yup.string().required("Clock is required"),
    date: Yup.string().required("Date is required"),
  });

  const getInputClasses = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true;
    }
    if (formik.touched[fieldName] && !formik.errors[fieldName]) {
      return false;
    }

    return false;
  };

  const onTypes = () => {
    const assets = tagLists?.map((suggestion) => ({
      value: suggestion.id,
      label: suggestion.name,
    }));
    setList(assets);
  };

  const form = useRef();
  const formik = useFormik({
    initialValues,
    validationSchema: CreateSchema,
    onSubmit: (values) => {
      const formData = new FormData(form.current);
      formData.append("title", String(values.title));
      formData.append("description", String(values.description));
      formData.append("link", values.link);
      const selectedTimeFrom = values.fromClock
        ? values.fromClock.toLocaleTimeString()
        : null;
      const selectedTimeTo = values.toClock
        ? values.toClock.toLocaleTimeString()
        : null;
      if (tagsApi?.length) {
        for (let i = 0; i < tagsApi?.length; i++) {
          formData.append("Interests", tagsApi?.[i].value);
        }
      }

      if (newsTagsApi?.length) {
        for (let i = 0; i < newsTagsApi?.length; i++) {
          formData.append("NewInterests", newsTagsApi?.[i].label);
        }
      }
      formData.append("fromClock", selectedTimeFrom);
      formData.append("toClock", selectedTimeTo);
      formData.append("promoted", isChecked);

      formData.append("eventFormat", String(values.eventFormat));
      formData.append("eventTypeId", String(values.eventTypeId));
      formData.append("date", dateConvertItemOther(values.date));
      formData.append("file", file);
      createEvent(formData);
    },
  });

  const { resetForm } = formik;
  const onHideModal = () => {
    onHide();
    resetForm();
    setImageSrc("");
    setFile("");
  };
  useEffect(() => {
    if (isTagListSuccess) onTypes();
  }, [isTagListSuccess]);

  useEffect(() => {
    if (isSuccess) {
      refetch();
      onHide();
      resetForm();
    }
  }, [isLoading]);

  return (
    <Modal
      size="xl"
      aria-labelledby="create"
      centered
      className="modal-center pr-0"
      show={show}
      onHide={onHideModal}
    >
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="create">Add</Modal.Title>
        </Modal.Header>
        <Modal.Body id="userModal">
          <div className="col-12 pl-4 pr-4">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="title"
                    name="title"
                    label="Name"
                    placeholder="Name"
                    className="w-100"
                    error={getInputClasses("title")}
                    {...formik.getFieldProps("title")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf
                  condition={formik.touched.title && formik.errors.title}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.title}</div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="link"
                    name="link"
                    label="Link"
                    placeholder="Link"
                    className="w-100"
                    error={getInputClasses("link")}
                    {...formik.getFieldProps("link")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf condition={formik.touched.link && formik.errors.link}>
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.link}</div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <DatePicker
                  selected={fromClock}
                  id="fromClock"
                  name="fromClock"
                  dateFormat="h:mm aa"
                  onChange={(value) => {
                    setFromClock(value);
                    formik.setFieldValue("fromClock", value);
                  }}
                  placeholderText="From Clock"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={5}
                  timeCaption="Time"
                />
                <RenderIf
                  condition={
                    formik.touched.fromClock && formik.errors.fromClock
                  }
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.fromClock}
                    </div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <DatePicker
                  selected={toClock}
                  id="toClock"
                  name="toClock"
                  dateFormat="h:mm aa"
                  onChange={(value) => {
                    setToClock(value);
                    formik.setFieldValue("toClock", value);
                  }}
                  placeholderText="To Clock"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={5}
                  timeCaption="Time"
                />
                <RenderIf
                  condition={formik.touched.toClock && formik.errors.toClock}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.toClock}</div>
                  </div>
                </RenderIf>
              </div>

              <div className="col-12 col-md-12 col-lg-6">
                <ReactSelect
                  options={selectedValues}
                  onChange={handleChangeValue}
                  single={selectedValue}
                  placeholder="Event Format"
                  inputId="eventFormat"
                />
                <RenderIf
                  condition={
                    formik.touched.eventFormat && formik.errors.eventFormat
                  }
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.eventFormat}
                    </div>
                  </div>
                </RenderIf>
              </div>

              <div className="col-12 col-md-12 col-lg-6">
                <ReactSelect
                  options={eventTypeOptions}
                  onChange={handleChangeReason}
                  single={eventTypeOption}
                  placeholder="Event Type"
                  inputId="eventTypeId"
                />
                <RenderIf
                  condition={
                    formik.touched.eventTypeId && formik.errors.eventTypeId
                  }
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.eventTypeId}
                    </div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6 mt-4">
                <CreatableSelect
                  isMulti
                  onChange={handleOnChange}
                  value={selected}
                  placeholder="Tags"
                  options={list}
                  id="creatable-select"
                  instanceId="creatable-select"
                  className="creatable-select"
                  classNamePrefix="creatable-react-select"
                />
              </div>
              <div className="col-12 col-md-12 col-lg-6 mt-4">
                <DatePickers
                  selected={date}
                  id="date"
                  name="date"
                  dateFormat="dd-MM-yyyy"
                  onChange={(value) => {
                    setDate(value);
                    formik.setFieldValue("date", formatDate(value));
                  }}
                  placeholderText="Date"
                />
                <RenderIf condition={formik.touched.date && formik.errors.date}>
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.date}</div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6 mt-4">
                <div className="d-flex align-items-center">
                  <Checkbox
                    {...label}
                    color="success"
                    onChange={handleChange}
                    checked={isChecked}
                  />
                  <h6 className="mb-0">Promoted</h6>
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-6 mt-4">
                <input
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  name="file"
                  label="File"
                  placeholder="File"
                  className="w-100"
                  onChange={imagePreview}
                />
                <RenderIf condition={formik.touched.file && formik.errors.file}>
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.file}</div>
                  </div>
                </RenderIf>

                <RenderIf condition={imageSrc}>
                  <div className="image-preview mt-4">
                    <div
                      style={{
                        position: "relative",
                        width: "100px",
                        height: "100px",
                      }}
                    >
                      <img
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                          borderRadius: "100%",
                        }}
                        src={imageSrc}
                        alt="img"
                      />
                      <button
                        style={{
                          position: "absolute",
                          right: "-30px",
                        }}
                        className="BtnSvg"
                        onClick={onClick}
                      >
                        <i
                          style={{ fontSize: "10px" }}
                          className="flaticon2-delete"
                        ></i>
                      </button>
                    </div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-12 mt-4">
                <RichTextEditor
                  onChange={(newContent) => {
                    const trimmedContent = newContent.trim();
                    const isEmpty = trimmedContent === "<p><br></p>";
                    formik.setFieldValue(
                      "description",
                      isEmpty ? "" : newContent
                    );
                  }}
                  value={formik?.values?.description}
                />
                <RenderIf
                  condition={
                    formik.touched.description && formik.errors.description
                  }
                  renderElse={null}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.description}
                    </div>
                  </div>
                </RenderIf>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
              formik.resetForm();
            }}
          >
            Close
          </Button>
          <Button type="submit" disabled={isLoading} className={`px-9`}>
            Add
            <RenderIf condition={isLoading}>
              <span className="ml-3 spinner spinner-white"></span>
            </RenderIf>
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
