import React, { useState } from "react";
import { CiHashtag } from "react-icons/ci";
import { makeStyles } from "@material-ui/core/styles/index";
import Drawer from "@material-ui/core/Drawer/index";
import Divider from "@material-ui/core/Divider/index";
import CloseIcon from "@material-ui/icons/Close";
import Card from "@material-ui/core/Card/index";
import CardHeader from "@material-ui/core/CardHeader/index";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import "../../../../_metronic/_assets/plugins/flaticon2/flaticon.css";
import { useGetEventQuery } from "../../../../redux/api/Events/eventsApi";
const { REACT_APP_BASE_PHOTO_URL_MAIN } = process.env;

const useStyles = makeStyles({
  list: {
    width: 400,
    height: "100vh",
  },
  card: {
    margin: "30px",
  },
  pointer: {
    cursor: "pointer",
  },
});

export default function View(props) {
  const { onClose, id } = props;

  const { data } = useGetEventQuery(id);

  const classes = useStyles();
  const sideList = (side) => (
    <div className={classes.list} role="presentation">
      <div className="col-12 d-flex align-items-center justify-content-between py-4">
        <h1 className="h3 mb-0">Events</h1>
        <CloseIcon className={classes.pointer} onClick={onClose} />
      </div>
      <Divider className="bg-dark" />
      <div className="drawer-body">
        <Card className={classes.card}>
          <CardHeader
            title="General information"
            className="bg-secondary text-dark h6 p-3"
            disableTypography
          />

          <CardHeader
            avatar={
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Chat6.svg")}
                className="icon-primary"
              />
            }
            title={data?.title}
            subheader="Name"
            className="p-3"
          />
          <CardHeader
            avatar={
              <i class="flaticon2-schedule icon-flaticon text-primary"></i>
            }
            title={data?.date}
            subheader="Tarix"
            className="p-3"
          />

          <CardHeader
            avatar={
              <div className="my-2">
                <img
                  style={{
                    width: "40px",
                    height: "40px",
                    objectFit: "cover",
                    borderRadius: "100%",
                  }}
                  src={`${REACT_APP_BASE_PHOTO_URL_MAIN}${data?.fileUrl}`}
                  alt="contributor"
                />
              </div>
            }
            subheader="Photo"
            className="p-3"
          />
        </Card>

        <Card className={classes.card}>
          <CardHeader
            title="Additional information"
            className="bg-secondary text-dark h6 p-3"
            disableTypography
          />

          <CardHeader
            avatar={<i class="flaticon2-file-1 icon-flaticon text-warning"></i>}
            title={
              <p
                dangerouslySetInnerHTML={{
                  __html: data?.description,
                }}
              ></p>
            }
            subheader="Description"
            className="p-3"
          />
          <CardHeader
            avatar={<i class="flaticon2-file-1 icon-flaticon text-warning"></i>}
            title={data?.eventFormatText}
            subheader="Event Format"
            className="p-3"
          />
          <CardHeader
            avatar={<i class="flaticon2-file-1 icon-flaticon text-warning"></i>}
            title={data?.eventType?.title}
            subheader="Event Type"
            className="p-3"
          />
          <CardHeader
            avatar={
              <i class="flaticon2-hourglass-1 icon-flaticon text-primary"></i>
            }
            title={data?.fromClock.slice(11, 16)}
            subheader="From Clock"
            className="p-3"
          />
          <CardHeader
            avatar={
              <i class="flaticon2-hourglass-1 icon-flaticon text-primary"></i>
            }
            title={data?.toClock.slice(11, 16)}
            subheader="To Clock"
            className="p-3"
          />
          <CardHeader
            avatar={<CiHashtag />}
            title={data?.eventInterests?.map((c) => (
              <span key={c?.id} style={{ marginRight: "5px" }}>
                {`#${c?.interestName}`}
              </span>
            ))}
            subheader="Tags"
            className="p-3"
          />
        </Card>
      </div>
    </div>
  );

  return (
    <Drawer {...props} anchor="right" className="drawer--fixed">
      {sideList("right")}
    </Drawer>
  );
}
