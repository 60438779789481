import React from "react";
import { makeStyles } from "@material-ui/core/styles/index";
import Drawer from "@material-ui/core/Drawer/index";
import Divider from "@material-ui/core/Divider/index";
import CloseIcon from "@material-ui/icons/Close";
import Card from "@material-ui/core/Card/index";
import CardHeader from "@material-ui/core/CardHeader/index";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import "../../../../_metronic/_assets/plugins/flaticon2/flaticon.css";
import { useGetCourseQuery } from "../../../../redux/api/Library/libraryApi";
import { getTitleByComplexity } from "../../../../Helpers/updateObject";
const { REACT_APP_BASE_PHOTO_URL_MAIN } = process.env;

const useStyles = makeStyles({
  list: {
    width: 400,
    height: "100vh",
  },
  card: {
    margin: "30px",
  },
  pointer: {
    cursor: "pointer",
  },
});

export default function View(props) {
  const { onClose, id } = props;

  const { data } = useGetCourseQuery(id);

  const classes = useStyles();
  const sideList = (side) => (
    <div className={classes.list} role="presentation">
      <div className="col-12 d-flex align-items-center justify-content-between py-4">
        <h1 className="h3 mb-0">Hubdev Course</h1>
        <CloseIcon className={classes.pointer} onClick={onClose} />
      </div>
      <Divider className="bg-dark" />
      <div className="drawer-body">
        <Card className={classes.card}>
          <CardHeader
            title="General information"
            className="bg-secondary text-dark h6 p-3"
            disableTypography
          />

          <CardHeader
            avatar={
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Chat6.svg")}
                className="icon-primary"
              />
            }
            title={data?.title}
            subheader="Title"
            className="p-3"
          />
          <CardHeader
            avatar={<i class="flaticon2-file-1 icon-flaticon text-warning"></i>}
            title={data?.hubDevCourseType?.title}
            subheader="Course Type"
            className="p-3"
          />
          <CardHeader
            avatar={<i class="flaticon2-file-1 icon-flaticon text-warning"></i>}
            title={data?.hubDevCourseOrganization?.title}
            subheader="Course Organization"
            className="p-3"
          />
          <CardHeader
            avatar={<i class="flaticon2-file-1 icon-flaticon text-warning"></i>}
            title={`${data?.lenghtInMinutes} min`}
            subheader="Duration"
            className="p-3"
          />
          <CardHeader
            avatar={<i class="flaticon2-file-1 icon-flaticon text-warning"></i>}
            title={data?.scrollCourseId}
            subheader="Scroll Course Id"
            className="p-3"
          />
          <CardHeader
            avatar={<i class="flaticon2-file-1 icon-flaticon text-warning"></i>}
            title={data?.orderingId}
            subheader="Order Id"
            className="p-3"
          />
          <CardHeader
            avatar={<i class="flaticon2-file-1 icon-flaticon text-warning"></i>}
            title={data?.rating}
            subheader="Rating"
            className="p-3"
          />
          <CardHeader
            avatar={<i class="flaticon2-file-1 icon-flaticon text-warning"></i>}
            title={data?.iFrameUrl}
            subheader="Url"
            className="p-3"
          />
          <CardHeader
            avatar={<i class="flaticon2-file-1 icon-flaticon text-warning"></i>}
            title={getTitleByComplexity(data?.complexity)}
            subheader="Complexity"
            className="p-3"
          />
          <CardHeader
            avatar={
              <div className="my-2">
                <img
                  style={{
                    width: "40px",
                    height: "40px",
                    objectFit: "cover",
                    borderRadius: "100%",
                  }}
                  src={`${REACT_APP_BASE_PHOTO_URL_MAIN}${data?.iconFileUrl}`}
                  alt="contributor"
                />
              </div>
            }
            subheader="Photo"
            className="p-3"
          />
        </Card>

        <Card className={classes.card}>
          <CardHeader
            title="Additional information"
            className="bg-secondary text-dark h6 p-3"
            disableTypography
          />

          <CardHeader
            avatar={<i class="flaticon2-sheet icon-flaticon"></i>}
            title={
              <p
                dangerouslySetInnerHTML={{
                  __html: data?.description,
                }}
              ></p>
            }
            subheader="Description"
            className="p-3"
          />

          <CardHeader
            avatar={<i class="flaticon2-sheet icon-flaticon"></i>}
            title={data?.hubDevCategory?.title}
            subheader="Category"
            className="p-3"
          />
          <CardHeader
            avatar={<i class="flaticon2-sheet icon-flaticon"></i>}
            title={data?.experts?.map(
              (c) => ` ${c?.firstName} ${c?.lastName} `
            )}
            subheader="Experts"
            className="p-3"
          />
          <CardHeader
            avatar={<i class="flaticon2-sheet icon-flaticon"></i>}
            title={data?.courseInterests?.map((c) => ` #${c?.name} `)}
            subheader="Tags"
            className="p-3"
          />
        </Card>
      </div>
    </div>
  );

  return (
    <Drawer {...props} anchor="right" className="drawer--fixed">
      {sideList("right")}
    </Drawer>
  );
}
