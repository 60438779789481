import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import { v4 as uuid } from "uuid";
import { FileUploader } from "react-drag-drop-files";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import TextField from "@material-ui/core/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ReactSelect, RenderIf, RichTextEditor } from "../../../components";
import { useCreateNewsMutation } from "../../../../redux/api/News/newsApi";
import { videoTypes } from "../../../constants";
import { useGetInterestsWithoutFilterQuery } from "../../../../redux/api/Interests/interestsApi";

export default function Create(props) {
  const { show, onHide, refetch } = props;
  const {
    data: tagList,
    isSuccess: isTagListSuccess,
  } = useGetInterestsWithoutFilterQuery();
  const tagLists = tagList?.data;

  const [list, setList] = useState([]);
  const [videos, setVideos] = useState([]);
  const [videoUrls, setVideoUrls] = useState([]);
  const [imageSrc, setImageSrc] = useState([]);
  const [file, setFile] = useState([]);
  const [coverFile, setCoverFile] = useState();
  const [coveriImageSrc, setCoverImageSrc] = useState("");

  const [selected, setSelected] = useState([]);
  const [tagsApi, SetTagsApi] = useState([]);
  const [newsTagsApi, SetNewsTagsApi] = useState([]);
  const [defaultVal, setDefaultVal] = useState([]);

  const handleOnChange = (value) => {
    const difference = selected?.filter((x) => !value.includes(x));
    difference?.forEach((opt) => {
      if (tagsApi?.includes(opt)) {
        const TagsApiClone = tagsApi?.filter((optApi) => optApi !== opt);
        SetTagsApi(TagsApiClone);
      } else {
        const newsTagsApiClone = newsTagsApi?.filter(
          (optApi) => optApi !== opt
        );
        SetNewsTagsApi(newsTagsApiClone);
      }
    });
    value?.forEach((val) => {
      if (!defaultVal?.includes(val)) {
        if (val?.__isNew__) {
          !newsTagsApi?.includes(val) && SetNewsTagsApi([...newsTagsApi, val]);
        } else {
          !tagsApi?.includes(val) &&
            !defaultVal?.includes(val) &&
            SetTagsApi([...tagsApi, val]);
        }
      }
    });
    setSelected(value);
  };

  const CoverImagePreview = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    setCoverFile(file);
    formik.setFieldValue("coverFile", file);

    reader.onloadend = () => {
      setCoverImageSrc(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const onClick = (e) => {
    setCoverImageSrc("");
    setCoverFile("");
  };

  const handleChangeVideo = (el) => {
    const videoList = [];
    for (let i = 0; i < el.length; i++) {
      const file = el[i];

      videoList.push(file);
    }
    const newVideoUrls = videoList.map((file) => {
      const video = {
        ...file,
        name: file.name,
        type: file.type,
        url: URL.createObjectURL(file),
      };
      return video;
    });
    setVideoUrls((prevUrls) => [...prevUrls, ...newVideoUrls]);

    setVideos([...videos, ...videoList]);
  };
  const imagePreview = (e) => {
    let reader = new FileReader();
    let files = e.target.files;
    let fileArray = [];

    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      formik.setFieldValue("imageFiles", files[i]);

      fileArray.push(file);
      reader.onloadend = () => {
        let imageSrcArray = [...imageSrc];
        imageSrcArray.push({
          src: reader.result,
          id: uuid(),
        });
        setImageSrc(imageSrcArray);
      };

      reader.readAsDataURL(file);
    }

    setFile((prevFiles) => [...prevFiles, ...fileArray]);
  };

  const handleRemoveFileItem = (index, el) => {
    const updatedVideos = [...videos];
    updatedVideos.splice(index, 1);
    setVideos(updatedVideos);
    setVideoUrls(updatedVideos);
  };

  const onTypes = () => {
    const assets = tagLists?.map((suggestion) => ({
      value: suggestion.id,
      label: suggestion.name,
    }));
    setList(assets);
  };

  useEffect(() => {
    if (isTagListSuccess) onTypes();
  }, [isTagListSuccess]);
  const options = [
    { id: 1, value: "News", label: "News" },
    { id: 2, value: "Announcements", label: "Announcements" },
  ];

  const [selectedValue, setSelectedValue] = useState(false);

  function handleChangeType(value) {
    setSelectedValue(value);
    formik.setFieldValue("typeId", value.value);
  }

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const handleRemoveImage = (id) => {
    const updatedImageSrc = imageSrc?.filter((item) => item.id !== id);
    const updatedFiles = file?.filter((item, index) => index !== id);
    setImageSrc(updatedImageSrc);
    setFile(updatedFiles);
  };
  const initialValues = {
    title: "",
    subTitle: "",
    description: "",
    typeId: "",
    file: "",
    coverFile: "",
    newTags: [],
    tags: [],
    imageFiles: "",
    videoFiles: [],
  };
  const [createNews, { isLoading, error, isSuccess }] = useCreateNewsMutation();

  const CreateSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    subTitle: Yup.string().required("Subtitle is required"),
    description: Yup.string()
      .required("Description is required")
      .test("rich-text-not-empty", "Description cannot be empty", (value) => {
        if (typeof value !== "string") return false;
        const trimmedValue = value.trim();
        return trimmedValue !== "<p><br></p>" && trimmedValue !== "";
      }),
    typeId: Yup.string().required("Type is required"),
    // imageFiles: Yup.string().required("File is required"),
    coverFile: Yup.string().required("File is required"),
  });

  const getInputClasses = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true;
    }
    if (formik.touched[fieldName] && !formik.errors[fieldName]) {
      return false;
    }

    return false;
  };

  const form = useRef();
  const formik = useFormik({
    initialValues,
    validationSchema: CreateSchema,
    onSubmit: (values) => {
      const formData = new FormData(form.current);

      formData.append("title", String(values.title));
      formData.append("subTitle", String(values.subTitle));
      formData.append("typeId", selectedValue?.id);
      formData.append("description", String(values.description));

      if (tagsApi?.length) {
        for (let i = 0; i < tagsApi?.length; i++) {
          formData.append("Interests", tagsApi?.[i].value);
        }
      }

      if (newsTagsApi?.length) {
        for (let i = 0; i < newsTagsApi?.length; i++) {
          formData.append("NewInterests", newsTagsApi?.[i].label);
        }
      }
      if (videos.length) {
        for (let i = 0; i < videos?.length; i++) {
          formData.append(`videoFiles`, videos?.[i]);
        }
      }

      if (file?.length) {
        for (let i = 0; i < file?.length; i++) {
          formData.append(`imageFiles`, file?.[i]);
        }
      }

      formData.append("file", coverFile);
      createNews(formData);
    },
  });

  const { resetForm } = formik;
  const onHideModal = () => {
    onHide();
    resetForm();
    setImageSrc("");
    setFile("");
  };

  useEffect(() => {
    if (isSuccess) {
      refetch();
      onHide();
      resetForm();
    }
  }, [isLoading]);
  return (
    <Modal
      size="xl"
      aria-labelledby="create"
      centered
      className="modal-center pr-0"
      show={show}
      onHide={onHideModal}
    >
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="create">Add</Modal.Title>
        </Modal.Header>
        <Modal.Body id="userModal">
          <div className="col-12 pl-4 pr-4">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="title"
                    name="title"
                    label="Name"
                    placeholder="Name"
                    className="w-100"
                    error={getInputClasses("title")}
                    {...formik.getFieldProps("title")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf
                  condition={formik.touched.title && formik.errors.title}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.title}</div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="subTitle"
                    name="subTitle"
                    label="SubTitle"
                    placeholder="SubTitle"
                    className="w-100"
                    error={getInputClasses("subTitle")}
                    {...formik.getFieldProps("subTitle")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf
                  condition={formik.touched.subTitle && formik.errors.subTitle}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.subTitle}
                    </div>
                  </div>
                </RenderIf>
              </div>

              <div className="col-12 col-md-12 col-lg-6">
                <ReactSelect
                  options={options}
                  onChange={handleChangeType}
                  single={selectedValue}
                  placeholder="Type"
                  inputId="typeId"
                />
                <RenderIf
                  condition={formik.touched.typeId && formik.errors.typeId}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.typeId}</div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6 mt-4">
                <CreatableSelect
                  isMulti
                  onChange={handleOnChange}
                  value={selected}
                  placeholder="Tags"
                  options={list}
                  id="creatable-select"
                  instanceId="creatable-select"
                  className="creatable-select"
                  classNamePrefix="creatable-react-select"
                />
              </div>
              <div className="col-12 col-md-12 col-lg-6 mt-3">
                <span>News slider</span>
                <input
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  name="file"
                  label="File"
                  placeholder="File Slider"
                  className="w-100"
                  multiple
                  onChange={imagePreview}
                />

                <RenderIf condition={imageSrc?.length}>
                  <div className="fileContent fileSlider mt-3">
                    <Carousel
                      infiniteLoop
                      showArrows={true}
                      showThumbs={true}
                      className="editSlider"
                      selectedItem={imageSrc?.length - 1}
                    >
                      {imageSrc?.map((el, index) => {
                        return (
                          <div className="otherPhotoImgSide" key={index}>
                            <img
                              style={{
                                objectFit: "cover",
                              }}
                              src={el.src}
                              alt="img"
                            />
                            <button
                              style={{
                                position: "absolute",
                                left: "30px",
                                top: "10px",
                              }}
                              className="BtnSvg"
                              onClick={() => handleRemoveImage(el?.id)}
                            >
                              <i
                                style={{
                                  fontSize: "10px",
                                }}
                                className="flaticon2-delete"
                              ></i>
                            </button>
                          </div>
                        );
                      })}
                    </Carousel>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6 selectImg">
                <FileUploader
                  types={videoTypes}
                  accept="video/*"
                  multiple
                  handleChange={handleChangeVideo}
                  onChange={handleChangeVideo}
                />

                <RenderIf condition={videos?.length}>
                  <div className="fileContent fileSlider">
                    <Carousel
                      infiniteLoop
                      showArrows={true}
                      showThumbs={true}
                      className="editSlider"
                      selectedItem={videos?.length - 1}
                    >
                      {videoUrls?.map((el, index) => {
                        return (
                          <div className="otherPhotoImgSide" key={index}>
                            <video src={el?.url} controls></video>

                            <div
                              className="deleteIcon"
                              onClick={() => handleRemoveFileItem(index)}
                            >
                              <i className="flaticon2-cancel-music"></i>
                            </div>
                          </div>
                        );
                      })}
                    </Carousel>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6 mt-3">
                <span>Cover photo</span>
                <input
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  name="file"
                  label="Cover File"
                  placeholder="Cover file"
                  className="w-100"
                  onChange={CoverImagePreview}
                />
                <RenderIf
                  condition={
                    formik.touched.coverFile && formik.errors.coverFile
                  }
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.coverFile}
                    </div>
                  </div>
                </RenderIf>
                <RenderIf condition={coveriImageSrc?.length}>
                  <div className="image-preview mt-4">
                    <div
                      style={{
                        position: "relative",
                        width: "100px",
                        height: "100px",
                      }}
                    >
                      <img
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                          borderRadius: "100%",
                        }}
                        src={coveriImageSrc}
                        alt="img"
                      />
                      <button
                        style={{
                          position: "absolute",
                          right: "-30px",
                        }}
                        className="BtnSvg"
                        onClick={onClick}
                      >
                        <i
                          style={{ fontSize: "10px" }}
                          className="flaticon2-delete"
                        ></i>
                      </button>
                    </div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-12 mt-3">
                <RichTextEditor
                  onChange={(newContent) => {
                    const trimmedContent = newContent.trim();
                    const isEmpty = trimmedContent === "<p><br></p>";
                    formik.setFieldValue(
                      "description",
                      isEmpty ? "" : newContent
                    );
                  }}
                  value={formik?.values?.description}
                />
                <RenderIf
                  condition={
                    formik.touched.description && formik.errors.description
                  }
                  renderElse={null}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.description}
                    </div>
                  </div>
                </RenderIf>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
              formik.resetForm();
            }}
          >
            Close
          </Button>
          <Button type="submit" disabled={isLoading} className={`px-9`}>
            Add
            <RenderIf condition={isLoading}>
              <span className="ml-3 spinner spinner-white"></span>
            </RenderIf>
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
