import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@mui/material/Checkbox";
import CreatableSelect from "react-select/creatable";
import { Formik } from "formik";
import DatePicker from "react-datepicker";
import * as Yup from "yup";
import { RenderIf, ReactSelect, RichTextEditor } from "../../../components";
import {
  useGetAllEventTypeWithoutFilterQuery,
  useGetEventQuery,
  useUpdateEventMutation,
} from "../../../../redux/api/Events/eventsApi";
import { formatDate } from "../../../../Helpers/formatDate";
import { dateConvertItemOther } from "../../../../Helpers/dateConvertOther";
import DatePickers from "../../../components/ReactDatePicker/DatePicker";
import { useGetInterestsWithoutFilterQuery } from "../../../../redux/api/Interests/interestsApi";

const { REACT_APP_BASE_PHOTO_URL_MAIN } = process.env;
const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function Edit(props) {
  const { onHide, show, refetch, id } = props;
  const {
    data: eventData,
    isFetching,
    isSuccess: dataSuccess,
  } = useGetEventQuery(id);
  const eventTags = eventData?.eventInterests;
  const {
    data: tagList,
    isSuccess: isTagListSuccess,
  } = useGetInterestsWithoutFilterQuery();
  const tagLists = tagList?.data;
  const [content, setContent] = useState(false);
  const [list, setList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [defaultVal, setDefaultVal] = useState([]);
  const [tagsApi, SetTagsApi] = useState([]);
  const [newsTagsApi, SetNewsTagsApi] = useState([]);
  const [deletedTags, setDeletedTags] = useState([]);
  const [eventTypes, setEventTypes] = useState([]);
  const [eventType, setEventType] = React.useState(null);
  const [eventFormat, setEventFormat] = React.useState(null);
  const [fromClock, setFromClock] = useState(null);
  const [toClock, setToClock] = useState(null);
  const [date, setDate] = useState(null);
  const [isChecked, setIsChecked] = useState(null);
  const filePath = REACT_APP_BASE_PHOTO_URL_MAIN + eventData?.fileUrl;
  const [file, setFile] = useState("");
  const [imageSrc, setImageSrc] = useState(filePath);
  const handleOnChange = (value) => {
    const difference = selected?.filter((x) => !value.includes(x));
    difference?.forEach((opt) => {
      if (defaultVal?.includes(opt)) {
        setDeletedTags([...deletedTags, opt]);
      } else if (tagsApi?.includes(opt)) {
        const TagsApiClone = tagsApi?.filter((optApi) => optApi !== opt);
        SetTagsApi(TagsApiClone);
      } else {
        const newsTagsApiClone = newsTagsApi?.filter(
          (optApi) => optApi !== opt
        );
        SetNewsTagsApi(newsTagsApiClone);
      }
    });
    value?.forEach((val) => {
      if (!defaultVal?.includes(val)) {
        if (val?.__isNew__) {
          !newsTagsApi?.includes(val) && SetNewsTagsApi([...newsTagsApi, val]);
        } else {
          !tagsApi?.includes(val) &&
            !defaultVal?.includes(val) &&
            SetTagsApi([...tagsApi, val]);
        }
      }
    });
    setSelected(value);
  };
  const onTypes = () => {
    const assets = tagLists?.map((suggestion) => ({
      value: suggestion?.id,
      label: suggestion?.name,
    }));
    setList(assets);
    const selectedDefault = eventTags?.map((item) => {
      return {
        value: item?.id,
        label: item?.interestName,
      };
    });
    setSelected(selectedDefault);
    setDefaultVal(selectedDefault);
  };

  const handleCheckedChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const { data: allType } = useGetAllEventTypeWithoutFilterQuery();
  const options = [
    { value: 1, label: "Online" },
    { value: 2, label: "Offline" },
  ];
  function handleChangeFormat(value, setFieldValue) {
    setEventFormat((prevState) => value);
    setFieldValue("eventFormat", value.value);
  }

  function handleChangeType(value, setFieldValue) {
    setEventType((prevState) => value);
    setFieldValue("eventTypeId", value.value);
  }

  const imagePreview = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    setFile(e.target.value);

    reader.onloadend = () => {
      setImageSrc(reader.result);
    };

    reader.readAsDataURL(file);
  };
  const onClick = (e) => {
    setImageSrc("");
    setFile("");
  };

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const [
    updateEvent,
    { isLoading, error, isSuccess },
  ] = useUpdateEventMutation();

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    const hours = date
      .getUTCHours()
      .toString()
      .padStart(2, "0");
    const minutes = date
      .getUTCMinutes()
      .toString()
      .padStart(2, "0");

    return `${hours}:${minutes}`;
  };
  const form = useRef(null);

  const EditSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    file: Yup.string().required("File is required"),

    description: Yup.string()
      .required("Description is required")
      .test("rich-text-not-empty", "Description cannot be empty", (value) => {
        if (typeof value !== "string") return false;
        const trimmedValue = value.trim();
        return trimmedValue !== "<p><br></p>" && trimmedValue !== "";
      }),
  });

  const getInputClasses = (touched, errors, FieldName) => {
    if (touched[FieldName] && errors[FieldName]) {
      return true;
    }
    if (touched[FieldName] && errors[FieldName]) {
      return false;
    }

    return false;
  };

  const onPutEvent = (values, resetForm, setSubmitting) => {
    const event = new FormData(form.current);
    event.append("Id", id);
    event.append("description", content);

    if (tagsApi?.length) {
      for (let i = 0; i < tagsApi?.length; i++) {
        event.append("Interests", tagsApi?.[i].value);
      }
    }
    if (deletedTags?.length) {
      for (let i = 0; i < deletedTags?.length; i++) {
        event.append("DeletedInterests", deletedTags?.[i].value);
      }
    }
    if (newsTagsApi?.length) {
      for (let i = 0; i < newsTagsApi?.length; i++) {
        event.append("NewInterests", newsTagsApi?.[i].label);
      }
    }
    event.append("date", dateConvertItemOther(values.date));
    event.append("promoted", isChecked);
    event.append("eventFormat", String(values.eventFormat));
    event.append("link", values.link);
    event.append("eventTypeId", String(values.eventTypeId));
    updateEvent(event);
  };
  useEffect(() => {
    if (eventData) {
      setContent(eventData?.description);
    }
  }, [eventData]);
  useEffect(() => {
    if (isSuccess) {
      refetch();
      onHide();
    }
  }, [isLoading]);
  useEffect(() => {
    if (!isFetching && dataSuccess) {
      setIsChecked(eventData?.promoted);
    }
  }, [isFetching, dataSuccess]);
  useEffect(() => {
    if (isTagListSuccess && dataSuccess) onTypes();
  }, [isTagListSuccess, dataSuccess]);
  useEffect(() => {
    setImageSrc(filePath);
  }, [filePath]);
  useEffect(() => {
    setEventTypes(
      allType?.data?.map((suggestion) => ({
        value: suggestion?.id,
        label: suggestion?.title,
      }))
    );
  }, [allType]);

  useEffect(() => {
    setEventType((prev) =>
      eventTypes?.find((option) => option.value === eventData?.eventTypeId)
    );
  }, [eventData?.eventTypeId, eventTypes]);
  useEffect(() => {
    setEventFormat((prev) =>
      options?.find((option) => option.value === eventData?.eventFormat)
    );
  }, [eventData?.eventFormat]);

  useEffect(() => {
    if (!show) {
      setEventTypes(null);
    }
  }, [show]);
  return (
    <Modal
      size="xl"
      onHide={onHide}
      show={show}
      aria-labelledby="edit"
      className="modal-center pr-0"
      centered
    >
      <RenderIf
        condition={
          Object.keys(eventData || {}).length &&
          eventData?.constructor === Object
        }
      >
        <Formik
          initialValues={{
            title: eventData?.title,
            description: eventData?.description,
            file: eventData?.fileUrl,
            eventTags: eventData?.eventTags,
            link: eventData?.link,
            eventTypeId: eventData?.eventTypeId,
            eventFormat: eventData?.eventFormat,
            promoted: eventData?.promoted,
            fromClock: eventData?.fromClock,
            toClock: eventData?.toClock,
            date: eventData?.date,
          }}
          validationSchema={EditSchema}
          onSubmit={(values, { resetForm, setSubmitting }) => {
            onPutEvent(values, resetForm, setSubmitting);
          }}
        >
          {({
            values,
            errors,
            touched,
            setValues,
            setFieldValue,
            handleSubmit,
            handleChange,
            handleBlur,
            resetForm,
          }) => (
            <>
              <form onSubmit={handleSubmit} ref={form}>
                <Modal.Header closeButton>
                  <Modal.Title id="edit">Edit</Modal.Title>
                </Modal.Header>
                <Modal.Body id="userModal">
                  <div className="col-12 pl-4 pr-4">
                    <div className="row">
                      <div className="col-12 col-md-12 col-lg-6">
                        <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                          <TextField
                            id="title"
                            name="title"
                            label="Title"
                            placeholder="Title"
                            className="w-100"
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={getInputClasses(touched, errors, "title")}
                            onKeyDown={handleEnter}
                          />
                        </div>
                        <RenderIf
                          condition={touched.title && errors.title}
                          renderElse={null}
                        >
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{errors.title}</div>
                          </div>
                        </RenderIf>
                      </div>
                      <div className="col-12 col-md-12 col-lg-6">
                        <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                          <TextField
                            id="link"
                            name="link"
                            label="Link"
                            placeholder="Link"
                            className="w-100"
                            value={values.link}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={getInputClasses(touched, errors, "link")}
                            onKeyDown={handleEnter}
                          />
                        </div>
                        <RenderIf
                          condition={touched.link && errors.link}
                          renderElse={null}
                        >
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{errors.link}</div>
                          </div>
                        </RenderIf>
                      </div>

                      <div className="col-12 col-md-12 col-lg-6">
                        <DatePicker
                          selected={fromClock}
                          value={formatTime(values.fromClock)}
                          id="fromClock"
                          name="fromClock"
                          dateFormat="h:mm aa"
                          onChange={(value) => {
                            setFromClock(value);
                            setFieldValue("fromClock", value);
                          }}
                          placeholderText="From Clock"
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={5}
                          timeCaption="Time"
                        />
                      </div>
                      <div className="col-12 col-md-12 col-lg-6">
                        <DatePicker
                          selected={toClock}
                          id="toClock"
                          value={formatTime(values.toClock)}
                          name="toClock"
                          dateFormat="h:mm aa"
                          onChange={(value) => {
                            setToClock(value);
                            setFieldValue("toClock", value);
                          }}
                          placeholderText="To Clock"
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={5}
                          timeCaption="Time"
                        />
                      </div>

                      <div className="col-12 col-md-12 col-lg-6">
                        <ReactSelect
                          options={eventTypes}
                          onChange={(value) =>
                            handleChangeType(value, setFieldValue)
                          }
                          single={eventType}
                          placeholder="Event Type"
                          instanceId="eventTypeId"
                        />
                      </div>
                      <div className="col-12 col-md-12 col-lg-6">
                        <ReactSelect
                          options={options}
                          onChange={(value) =>
                            handleChangeFormat(value, setFieldValue)
                          }
                          single={eventFormat}
                          placeholder="Event Format"
                          inputId="eventFormat"
                        />
                      </div>
                      <div className="col-12 col-md-12 col-lg-6 mt-4">
                        <CreatableSelect
                          placeholder="Tags"
                          isMulti
                          defaultValue={eventTags?.map((item) => {
                            return {
                              value: item?.interestId,
                              label: item?.interestName,
                            };
                          })}
                          onChange={handleOnChange}
                          value={selected}
                          options={list}
                          id="creatable-select"
                          instanceId="creatable-select"
                          className="creatable-select"
                          classNamePrefix="creatable-react-select"
                        />
                      </div>
                      <div className="col-12 col-md-12 col-lg-6">
                        <DatePickers
                          selected={date}
                          value={values.date}
                          id="date"
                          name="date"
                          dateFormat="dd-MM-yyyy"
                          onChange={(value) => {
                            setDate(value);
                            setFieldValue("date", formatDate(value));
                          }}
                          placeholderText="Date"
                        />
                      </div>

                      <div className="col-12 col-md-12 col-lg-6 mt-4">
                        <div className="d-flex align-items-center">
                          <Checkbox
                            {...label}
                            color="success"
                            onChange={handleCheckedChange}
                            checked={isChecked}
                          />
                          <h6 className="mb-0">Promoted</h6>
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-lg-6">
                        <div className="w-100 pt-3 pb-3">
                          <label htmlFor="contained-button-file">
                            <input
                              accept="image/*"
                              id="contained-button-file"
                              type="file"
                              name="file"
                              label="File {Path}"
                              placeholder="File Path"
                              className="w-100"
                              onChange={(e) => {
                                imagePreview(e);
                                setValues({
                                  ...values,
                                  file: e.target.files[0],
                                });
                              }}
                              value={file}
                            />
                          </label>
                          <RenderIf condition={touched.file && errors.file}>
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">{errors.file}</div>
                            </div>
                          </RenderIf>
                          <RenderIf condition={imageSrc}>
                            <div className="image-preview mt-4">
                              <div
                                style={{
                                  position: "relative",
                                  width: "100px",
                                  height: "100px",
                                }}
                              >
                                <img
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    objectFit: "cover",
                                    borderRadius: "100%",
                                  }}
                                  src={imageSrc}
                                  alt="img"
                                />
                                <button
                                  style={{
                                    position: "absolute",
                                    right: "-30px",
                                  }}
                                  className="BtnSvg"
                                  onClick={() => {
                                    onClick();
                                    setFieldValue("file", "");
                                  }}
                                >
                                  <i
                                    style={{
                                      fontSize: "10px",
                                    }}
                                    className="flaticon2-delete"
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </RenderIf>
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-lg-12">
                        <div className="w-100 pt-2 pb-3 ">
                          <RichTextEditor
                            onChange={(newContent) => {
                              const trimmedContent = newContent.trim();
                              const isEmpty = trimmedContent === "<p><br></p>";
                              setContent(isEmpty ? "" : newContent);
                              setFieldValue(
                                "description",
                                isEmpty ? "" : newContent
                              );
                            }}
                            value={content}
                          />
                        </div>
                        <RenderIf
                          condition={touched.description && errors.description}
                        >
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {errors.description}
                            </div>
                          </div>
                        </RenderIf>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="light"
                    onClick={() => {
                      onHide(false);
                      resetForm();
                    }}
                  >
                    Close
                  </Button>
                  <Button type="submit" disabled={isLoading} className={`px-9`}>
                    Save
                    <RenderIf condition={isLoading}>
                      <span className="ml-3 spinner spinner-white"></span>
                    </RenderIf>
                  </Button>
                </Modal.Footer>
              </form>
            </>
          )}
        </Formik>
      </RenderIf>
    </Modal>
  );
}
